import { serviceData } from "../data/serviceData";

import Banner from '../components/banner'


export default function Product() {
    return (
        <div>
            <Banner />

            <div className="grid sm:grid-cols-3 gap-4 justify-evenly p-12 grid-cols-1 open:bg-white dark:open:bg-slate-900 open:ring-1 open:ring-black/5 dark:open:ring-white/10">
                {serviceData.map((sData) => (
                    <details className="m-px shadow-2xl p-6 " key={sData.name}>
                        <summary className="sm:text-2xl text-indigo-600 select-none text-base font-semibold text-center pb-4 break-all">{sData.name}</summary>
                        <span className="sm:text-xl text-black dark:text-white  text-xs text-justify break-normal">{sData.description}</span>
                    </details>
                ))}
            </div>

        </div >
    )

}