import { testimonialData } from '../data/testimonialData.js'

export default function Testimonial() {
    return (
        <div>
            <hr />
            <div className="lg:text-center p-16">
                <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Testimonials</h2>
                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    What are our patients saying.
                </p>

                <div class="grid sm:grid-cols-3 gap-4 mb-8 md:mb-0 flex-between items-center p-8">
                    {testimonialData.map((tData) => (
                        <div class="bg-white dark:bg-gray-800 w-72 shadow-lg mx-auto rounded-xl p-4">
                            <p class="text-gray-600 dark:text-white">
                                <span class="font-bold text-indigo-500 text-lg">
                                    “
                                </span>
                                {tData.description}
                                <span class="font-bold text-indigo-500 text-lg">
                                    ”
                                </span>
                            </p>
                            <div class="flex items-center justify-center mt-4">
                                <div class="flex flex-col ml-2 justify-between">
                                    <span class="font-semibold text-indigo-500 text-sm">
                                        {tData.name}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}