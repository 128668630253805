export const serviceData = [
    {
        name: 'Covid Care',
        description: 'Our home care services for covid patients ensures that mildly symptomatic and asymptomatic COVID-19 patients get the medical treatment they need.',
    },
    {
        name: 'Critical Care',
        description: 'ICU@Home services include the care and supervision of highly trained critical care therapists, experts and nurses and at a significantly lower price than that of a hospital stay.',
    },
    {
        name: 'Phsyiotherapy',
        description: 'Physiotherapy has a wide range of specialties and people can take physiotherapy for any of the following medical issues: Neurological issues, Neuromusculoskeletal, Cardiovascular and Respiratory.',
    },
    {
        name: 'Nursing',
        description: 'An in-home nursing service offers personalized nursing care at home as offered in a typical hospital while being more compassionate towards the patient and gets integrated into the patient’s family and develops an emotional bond with the patient and their family.',
    },
    {
        name: 'Lab Tests',
        description: 'We offer complete body health check up packages for you and your family, keep in mind that annual health check up is really important as we grow up, because with the growing age we become more susceptible to various diseases so complete body health check is really important to detect health problems before they become serious or incurable.',
    },
    {
        name: 'Doctor Consultation',
        description: 'The in – home doctor service is a great service for old people or people with chronic illness who are unable to visit a hospital or prefer to be treated at home.',
    },
    {
        name: 'Elder Care',
        description: 'Our Elder Care Programs have been specially designed to proactively enhance the health and wellness of the elderly. As patients may need continuous support, this comprehensive package offers dedicated care to put families at ease with both short term care and long term programs ranging from 3-6 months.',
    },

    {
        name: 'Mother & Baby Care',
        description: 'Pregnancy care comprises of three parts prenatal care, during pregnancy and postnatal care. While a lot of attention is given to prenatal care, postnatal care is equally important. In fact, postnatal is a difficult period and the new mother requires a lot of care, guidance and attention for the proper recovery and good health of both the mother and the child. Giving birth can be both an emotional and stressful period for a new mother. ',
    },

    {
        name: 'Medical Procedures',
        description: 'Travelling when unwell can lead to complications. In order to minimise such risks wound dressing and urinary catheterization can now be done in the comfort of your home.',
    },


];
