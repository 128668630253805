export default function Team() {
    return (

        <div class="p-8 bg-white dark:bg-gray-800 rounded-lg shadow">
            <p class="text-center text-3xl font-bold text-indigo-600 dark:text-white">
                Professional Team
            </p>
            <p class="text-center mb-12 text-xl font-normal text-gray-500 dark:text-gray-200">
                Meet the best team in world
            </p>
            <div class="flex items-center flex-col md:flex-row justify evenly justify-center">
                <div class="p-4">
                    <div class="text-center mb-4 opacity-90">
                        <a href="/" class="block relative">
                            <img alt="profil" src="/images/person/1.jpg" class="mx-auto object-cover rounded-full h-40 w-40 " />
                        </a>
                    </div>
                    <div class="text-center">
                        <p class="text-2xl text-gray-800 dark:text-white">
                            Divya
                        </p>
                        <p class="text-xl text-gray-500 dark:text-gray-200 font-light">
                            Founder
                        </p>
                        <p class="text-md text-gray-500 dark:text-gray-400 max-w-xs py-4 font-light">
                            Divya started this organisation as a decision to help elders and went on to grow the organisation to this scale.
                        </p>
                    </div>

                </div>
                <div class="p-4">
                    <div class="text-center mb-4 opacity-90">
                        <a href="/" class="block relative">
                            <img alt="profil" src="/images/person/4.jpg" class="mx-auto object-cover rounded-full h-40 w-40 " />
                        </a>
                    </div>
                    <div class="text-center">
                        <p class="text-2xl text-gray-800 dark:text-white">
                            Harish
                        </p>
                        <p class="text-xl text-gray-500 dark:text-gray-200 font-light">
                            Co-Founder
                        </p>
                        <p class="text-md text-gray-500 dark:text-gray-400 max-w-xs py-4 font-light">
                            Harish is the co-founder and also the lead physiotherapist of the organisation.
                        </p>
                    </div>

                </div>
                <div class="p-4">
                    <div class="text-center mb-4 opacity-90">
                        <a href="/" class="block relative">
                            <img alt="profil" src="/images/person/3.jpg" class="mx-auto object-cover rounded-full h-40 w-40 " />
                        </a>
                    </div>
                    <div class="text-center">
                        <p class="text-2xl text-gray-800 dark:text-white">
                            Saad
                        </p>
                        <p class="text-xl text-gray-500 dark:text-gray-200 font-light">
                            Manager
                        </p>
                        <p class="text-md text-gray-500 dark:text-gray-400 max-w-xs py-4 font-light">
                            Saad is the one that handles all your queries, known for his down to earth nature, Saad will help you with all his queries.
                        </p>
                    </div>

                </div>
            </div>
        </div>

    )
}