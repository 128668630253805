import AboutBanner from "../components/aboutBanner";
import Team from "../components/team";


export default function AboutUs() {
    return (

        <div>
            <AboutBanner />

            <section>
                <div class="container max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-white dark:bg-gray-800">
                    <div class="flex flex-wrap -mx-8">
                        <div class="w-full lg:w-1/2 px-8">
                            <div class="mb-12 lg:mb-0 pb-12 lg:pb-0 border-b lg:border-b-0">
                                <h2 class="mb-4 text-3xl lg:text-4xl font-bold font-heading dark:text-white">
                                    About Divya Home Care Services                                </h2>
                                <p class="mb-8 leading-loose text-gray-500 dark:text-gray-300">
                                    We are in the health care and home care industry since past 5 years, and over the years our values and mission has never changed.
                                </p>
                            </div>
                        </div>
                        <div class="w-full lg:w-1/2 px-8">
                            <ul class="space-y-12">
                                <li class="flex -mx-4">
                                    <div class="px-4">
                                        <span class="flex w-16 h-16 mx-auto items-center justify-center text-2xl font-bold font-heading rounded-full bg-blue-50 text-blue-600">
                                            1
                                        </span>
                                    </div>
                                    <div class="px-4">
                                        <h3 class="my-4 text-xl font-semibold dark:text-white">
                                            World-class medical care
                                        </h3>
                                        <p class="text-gray-500 dark:text-gray-300 leading-loose text-sm">
                                            <span className=" font-semibold text-indigo-700">DHCS</span> delivers quality care with compassion. We bring world class medical care into our patients’ homes and aim to make primary healthcare not only more accessible, but also more affordable and accountable to our patients’ needs.
                                        </p>
                                    </div>
                                </li>
                                <li class="flex -mx-4">
                                    <div class="px-4">
                                        <span class="flex w-16 h-16 mx-auto items-center justify-center text-2xl font-bold font-heading rounded-full bg-blue-50 text-blue-600">
                                            2
                                        </span>
                                    </div>
                                    <div class="px-4">
                                        <h3 class="my-4 text-xl font-semibold dark:text-white ">
                                            Hospital like quality at your home
                                        </h3>
                                        <p class="text-gray-500 dark:text-gray-300 leading-loose text-sm">
                                            With <span className=" font-semibold text-indigo-700">DHCS</span>, you can be sure that you will receive hospital-quality healthcare in the comfort of your home. We provide doctors, nurses, and physiotherapists for home visits who have passed our rigorous hiring standards and have had their backgrounds and medical knowledge verified by senior doctors. We facilitate lab tests at home and medical equipment rentals, making health care more accessible for our patients.
                                        </p>
                                    </div>
                                </li>
                                <li class="flex -mx-4">
                                    <div class="px-4">
                                        <span class="flex w-16 h-16 mx-auto items-center justify-center text-2xl font-bold font-heading rounded-full bg-blue-50 text-blue-600">
                                            3
                                        </span>
                                    </div>
                                    <div class="px-4">
                                        <h3 class="my-4 text-xl font-semibold dark:text-white">
                                            Leading home care provider
                                        </h3>
                                        <p class="text-gray-500 dark:text-gray-300 leading-loose text-sm">
                                            <span className=" font-semibold text-indigo-700">DHCS</span> Medical’s clinical procedures were developed in consultation with leading home healthcare professionals in India, ensuring that you receive only the highest quality medical care; all of our doctors are members of international medical accreditation bodies. As a result of using our services, our patients are able to stay in their homes longer, save money, and have peace of mind.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>



            <Team />
        </div>
    )
}