export default function Ctaone() {
    return (

        <section class="bg-indigo-600">
            <div class="container mx-auto grid grid-cols-2 gap-8 md:grid-cols-4 py-8 text-center">
                <div>
                    <h5 class="text-5xl font-bold text-white">
                        <span class="inline text-white">
                            220
                        </span>
                        <span class="text-indigo-200">
                            +
                        </span>
                    </h5>
                    <p class="text-indigo-100 tracking-wide text-xs font-medium uppercase">
                        Patients
                    </p>
                </div>
                <div>
                    <h5 class="text-5xl font-bold text-white">
                        <span class="inline text-white">
                            10
                        </span>
                        <span class="text-indigo-200">
                            +
                        </span>
                    </h5>
                    <p class="text-indigo-100 tracking-wide text-xs font-medium uppercase">
                        Doctors
                    </p>
                </div>
                <div>
                    <h5 class="text-5xl font-bold text-white">
                        <span class="inline text-white">
                            40
                        </span>
                        <span class="text-indigo-200">
                            +
                        </span>
                    </h5>
                    <p class="text-indigo-100 tracking-wide text-xs font-medium uppercase">
                        Nurses
                    </p>
                </div>
                <div>
                    <h5 class="text-5xl font-bold text-white">
                        <span class="inline text-white">
                            5
                        </span>
                        <span class="text-indigo-200">
                            +
                        </span>
                    </h5>
                    <p class="text-indigo-100 tracking-wide text-xs font-medium uppercase">
                        Years in business
                    </p>
                </div>
            </div>
        </section>

    )
}