// import logo from './logo.svg';
import About from '../components/about'
import Ctaone from '../components/ctaOne';
import Hero from '../components/hero';
import Services from '../components/services';
import Testimonial from '../components/testimonial'

export default function Home() {
  return (
    <div>
      <Hero />
      <Services />
      <Ctaone />
      <About />
      <Testimonial />
    </div>
  );
}

