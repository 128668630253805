import IC1 from '../images/icons/1.png'
import IC2 from '../images/icons/2.png'
import IC3 from '../images/icons/3.png'
import IC4 from '../images/icons/4.png'
import IC5 from '../images/icons/5.png'
import IC6 from '../images/icons/6.png'

export default function Services() {
    return (

        <div class="container  mx-auto px-6 pt-20 pb-20 bg-white dark:bg-gray-800">
            <div class="mb-16 text-center pt-8 border-t-2">
                <h2 class="text-base text-indigo-600 font-semibold tracking-wide uppercase">
                    Services
                </h2>
                <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                    We offer wide range of services.
                </p>
            </div>
            {/* Services Card */}
            <div class="sm:flex flex-wrap justify-center items-center text-center gap-8 pb-8">
                <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-4 py-4 bg-white mt-6  shadow-lg rounded-lg dark:bg-gray-800">
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center  text-white">
                            <img src={IC1} alt="First icon" />
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold dark:text-white py-4">
                        Doctor at home
                    </h3>
                    <p class="text-md  text-gray-500 dark:text-gray-300 py-4">
                        DHCS's team of expert, specialized doctors
                    </p>
                </div>
                <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-4 py-4 mt-6 sm:mt-16 md:mt-20 lg:mt-24 bg-white shadow-lg rounded-lg dark:bg-gray-800">
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center  text-white">
                            <img src={IC2} alt="First icon" />
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold dark:text-white py-4">
                        Nurse at home
                    </h3>
                    <p class="text-md text-gray-500 dark:text-gray-300 py-4">
                        Compassionate and skilled nursing staff.
                    </p>
                </div>
                <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 mt-6  px-4 py-4 bg-white shadow-lg rounded-lg dark:bg-gray-800">
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center  text-white">
                            <img src={IC3} alt="First icon" />
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold dark:text-white py-4">
                        Physio at home
                    </h3>
                    <p class="text-md  text-gray-500 dark:text-gray-300 py-4">
                        Professionally trained physiotherapists
                    </p>
                </div>
                <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-4 py-4 bg-white mt-6  shadow-lg rounded-lg dark:bg-gray-800">
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center  text-white">
                            <img src={IC4} alt="First icon" />
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold dark:text-white py-4">
                        Investigation at home
                    </h3>
                    <p class="text-md  text-gray-500 dark:text-gray-300 py-4">
                        Sample collection and Vaccination Administration
                    </p>
                </div>
                <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-4 py-4 mt-6 sm:mt-16 md:mt-20 lg:mt-24 bg-white shadow-lg rounded-lg dark:bg-gray-800">
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center  text-white">
                            <img src={IC5} alt="First icon" />
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold dark:text-white py-4">
                        Medical equipment at home
                    </h3>
                    <p class="text-md text-gray-500 dark:text-gray-300 py-4">
                        Rent & Purchase equipment                    </p>
                </div>
                <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 mt-6  px-4 py-4 bg-white shadow-lg rounded-lg dark:bg-gray-800">
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center  text-white">
                            <img src={IC6} alt="First icon" />
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold dark:text-white py-4">
                        Elder care
                    </h3>
                    <p class="text-md  text-gray-500 dark:text-gray-300 py-4">
                        Long term partnership plans for geriatric care.   </p>
                </div>

            </div>

            <div className="rounded-md flex item-center justify-center">
                <a
                    href="/services"
                    className="  px-8 py-3 text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                >
                    View more
                </a>
            </div>
        </div>

    )
}