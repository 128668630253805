import LOGO from '../images/dhcs.png'

export default function Footer() {
    return (

        <footer class="p-4 bg-white rounded-lg shadow border-t-2 md:px-6 md:py-8 dark:bg-gray-800">
            <div class="sm:flex sm:items-center sm:justify-between">
                <a href="/" class="flex items-center mb-4 sm:mb-0">
                    <img src={LOGO} class="mr-3 h-40" alt="LOGO" />
                    {/* <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Flowbite</span> */}
                </a>
                <ul class="flex flex-wrap items-center mb-6 text-sm font-semibold text-gray-800  sm:mb-0 dark:text-gray-400">
                    <li>
                        <a href="/" class="mr-4  md:mr-6 hover:text-indigo-500">Home</a>
                    </li>
                    <li>
                        <a href="/about" class="mr-4  md:mr-6 hover:text-indigo-500">About</a>
                    </li>
                    <li>
                        <a href="/services" class="mr-4  md:mr-6 hover:text-indigo-500">Services</a>
                    </li>
                    <li>
                        <a href="/contact" class=" hover:text-indigo-500">Contact</a>
                    </li>
                </ul>
            </div>
            <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
            <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022 <a href="https://divyahealthcareservices.com" class="">DHCS™</a>. All Rights Reserved.
            </span>
        </footer>

    )
}