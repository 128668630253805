export const testimonialData = [
    {
        name: 'Paratha Sarathi',
        description: 'The physiotherapist behaved professionally and the service was good. Call center executives were also good at addressing my concerns Thank you Santosh You have honestly done your job here. I got the account into my account',
    },
    {
        name: 'Karthik',
        description: 'Harish was excellent with the service and he was able to diagnose the problem very soon and gave the right treatment. I was feeling better just after one session. Highly recommend him!',
    },
    {
        name: 'David',
        description: 'Dr. Bindu was very helpful for my recovery post surgery. She has done a lot of effort to make me feel relaxed as well as energetic at the same time. After her session I am back to my job now. Thanks.',
    },
    {
        name: 'Jayanth',
        description: '“I can’t thank DHCS enough for helping me recuperate from an ACL surgery. Kudos to Dr. Rajesh and Harish my physiotherapist for the amazing counsel and attention! “',
    },
    {
        name: 'Charan',
        description: 'Doctor Bindu understood patient’s details very quickly and helped the patient with exercise and is now walking very smoothly and efficiently. 5 Star from my side.',
    },
    {
        name: 'Nancy',
        description: 'Our Physio Dr. Jerard was very good and professional. We would like to request for the same physio when we visit India end of next year.',
    },
]