
import bg from '../images/aboutBanner.png'


export default function AboutBanner() {
    return (
        <div class=" pt-12 pb-12 bg-white dark:bg-gray-800">
                <div class="w-full bg-center bg-cover h-96" style={{ backgroundImage: "url(" + bg + ")" }}>
                    <div class="flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50">
                        <div class="text-center">
                            <h2 class="text-3xl font-extrabold text-indigo-600 dark:text-white sm:text-6xl">About Us </h2>
                            <span className="sm:text-xl text-white text-xm">DHCS - Healing At Home.
                            </span>
                        </div>
                    </div>
                </div>
        </div>

    )
}